<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
      <div slot-scope="row" slot="selectedSlot">
        <div class="selected-box">
          <a-checkbox class="checked" v-model:checked="row.checkStatus"></a-checkbox>
        </div>
      </div>
      <div
          slot="questionId"
          slot-scope="row"
      >
        <div>{{ row.questionId }}</div>
        <div class="link-text" @click="handleGoToVersionList(row.duibanId)" v-if="row.duibanId">({{ row.duibanId }})</div>
        <div @click="handleSameCurrencyList(row)" v-if="row.sameId" class="color-red cursor-pointer">（{{ row.sameId }}）</div>
      </div>
      <div
          slot="itemImage"
          slot-scope="row"
      >
        <div class="item-image-list">
          <img class="item-image" :src="row.showImageUrl" @click="handleShowBigImage(row, 0)">
          <img class="item-image" :src="row.revealImageUrl" @click="handleShowBigImage(row, 1)">
        </div>
        <div class="mt-10" v-if="row.duibanId">
          <a-button type="primary" size="small" @click="handleResetVersion(row)">重新对版</a-button>
        </div>
      </div>
<!--  难度等级    -->
      <div slot-scope="row" slot="questionLevel">
        <span v-if="row.questionLevel === 2 || row.questionLevel == null" class="color-green">简单</span>
        <span v-if="row.questionLevel === 4" class="color-red">困难</span>
      </div>
<!--   对版top5   -->
      <div slot="itemVersionTop" slot-scope="row">
        <div
            class="version-top"
            :class="item.bgColor"
            v-for="(item, index) in row.machineList"
            :key="index">
          [{{item.dikuVersionCount}}]{{ item.itemName + '·' + item.versionName + `（${item.distance}）` }}
        </div>
      </div>
<!--      问题来源    -->
      <div slot="itemQuestionSourceTypeSlot" slot-scope="row">
        <div :class="sourceTypeClassType[row.sourceType]">{{ computedSourceTypeMapText(row.sourceType) }}</div>
      </div>
<!--   确定版别   -->
      <div slot="itemConfirmVersion" slot-scope="row" class="color-green">
        <div v-if="row.confirmItemName || row.confirmVersionName">
          <span v-if="row.confirmItemName">{{ row.confirmItemName }}</span>
          <span v-if="row.confirmItemName">·</span>
          <span v-if="row.confirmVersionName">{{ row.confirmVersionName }}</span>
        </div>
        <div v-else>-</div>
      </div>
<!--  处理状态  -->
      <div slot="itemOperateStatus" slot-scope="row">
        <span v-if="row.operateStatus === 0 || row.operateStatus == null" class="color-red">待处理</span>
        <span v-if="row.operateStatus === -1" class="color-orange">搁置</span>
        <span v-if="row.operateStatus === 10">已确认进样本库</span>
        <span v-if="row.operateStatus === 20">已确认进标库</span>
        <span v-if="row.operateStatus === 4" class="color-6699ff">假币</span>
        <span v-if="row.operateStatus === 6" class="color-6699ff">无法分辨</span>
      </div>
      <!--  发布状态  -->
      <div slot="resultStatusSlot" slot-scope="row">
        <span v-if="row.answerStatus === 1" class="color-green">已回答</span>
        <span v-else class="color-red">未回答</span>
      </div>
      <!--  回答状态  -->
      <div slot="pushStatusSlot" slot-scope="row">
        <span v-if="row.questionStatus === 0 " class="color-red">未推送</span>
        <span v-if="row.questionStatus === 1" class="color-orange">已推送</span>
      </div>
      <!--  操作  -->
      <div slot="action" slot-scope="row">
        <div class="action-btn">
          <a-popconfirm
              title="确定删除吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleAuctionBtn(row, 'delete')"
          >
            <a-button
                v-if="row.answerStatus !== 1"
                size="small"
                type="danger"
                class="setup-btn mt-10"
            >删除</a-button>
          </a-popconfirm>
          <a-button
              v-if="row.answerStatus === 1"
              size="small"
              type="danger"
              disabled
              class="setup-btn mt-10"
          >删除</a-button>
        </div>
      </div>
    </a-table>
  </div>
</template>
<script>
import {questionTableListData} from "@/views/cmsPage/versionManage/duibanQuestionList/_data"
import {computedSourceType, sourceTypeClassType} from "@/views/cmsPage/versionManage/_data"
import {getResetVersionTopApi} from "@/axios/apis"

export default {
  components: {},
  props: ['pagination', 'list', 'loading'],
  data() {
    return {
      selectedRowKeys: [],
      tableList: questionTableListData,
      sourceTypeClassType,
    }
  },
  computed: {
    computedSourceTypeMapText() {
      return value => computedSourceType(value)
    }
  },
  watch: {
    loading() {
      this.selectedRowKeys = []
      this.$emit("selectChange", [])
    },
  },
  methods: {
    /** 重新对版 */
    async handleResetVersion(row) {
      if (!row.duibanId) return this.$message.warn('不支持对版')
      this.$loading.show()
      const res = await getResetVersionTopApi(row.duibanId)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message || '对版成功')
      this.$emit('resetVersion', row)
    },
    /** 同款币跳转 */
    handleSameCurrencyList(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?sameParagraphRecordId=${row.sameId}`;
      window.open(url)
    },
    /** 成功 */
    handleStoreSuccess() {
      this.$emit('successStore')
    },
    /** 点击操作 */
    async handleAuctionBtn(row, type) {
      if (type === 'delete') {
        const res = await this.axios("/dq_admin/duibanPushQuestion/deleteById", {
          params: { id: row.questionId },
        })
        if (res.status !== "200") return
        this.$message.success('成功')
        this.$emit('successStore')
      }
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page)
    },
    /** 去往对版列表 */
    handleGoToVersionList(id) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?recordId=${id}`
      window.open(url)
    },
    /** 放大图片 */
    handleShowBigImage(row, index) {
      this.$previewImg({
        current: index,
        list: [{img_url: row.showImageUrl}, {img_url: row.revealImageUrl}],
        baseImgField: "img_url",
        showMute: false,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.link-text {
  text-decoration: underline;
  color: #2d8cf0;
  cursor: pointer;
}
.item-image-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item-image {
    width: 100px;
  }
}
.color-red {
  color: red;
}
.color-orange {
  color: orange;
}
.color-green {
  color: green;
}
.color-blue {
  color: #2d8cf0;
}
.color-6699ff {
  color: #6699FF;
}
.red {
  color: #000c17 !important;
  background-color: #F3E5AB !important;
}
.orange {
  color: #000c17 !important;
  background-color: #FFE87C !important;
}
.green {
  color: #000c17 !important;
  background-color: #F2BB66 !important;
}
.gray {
  color: #000c17 !important;
  background-color: #E2A76F !important;
}
.gray-b {
  color: #000c17 !important;
  background-color: #EE9A4D !important;
}
.color-gray {
  color: gray;
}
.mt-10 {
  margin-top: 10px;
}
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.version-top {
  margin-bottom: 3px;
}
.cursor-pointer {
  margin-top: 3px;
  cursor: pointer;
}
.selected-box {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .checked {
    padding-top: 30px;
    width: 100px;
    height: 100px;
    label {
      span {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>